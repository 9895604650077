import React, { useState } from "react";
import Social from "../../components/Social";

const heroContent = {
    heroImage: "img/hero/gonzch_v3.jpeg",
    heroMobileImage: "gonzch_v3_mobile.jpeg",
    heroTitleName: "Gonzalo Chacaltana",
    heroDesignation: "Software Engineer Specialist",
    heroDescriptions: `Backend Software Engineer | Master in Data Science | Tech Blogger Amateur |
  Self-taught | Deep Learner about Software Scalable and Data Analytics.`,
};


const Hero = () => {
    useState(true);
    return (
        <>
            <div className="row home-details-container align-items-center">
                <div
                    className="col-lg-4 bg position-fixed d-none d-lg-block"
                    style={{
                        backgroundImage: `url(${heroContent.heroImage
                            })`,
                    }}
                ></div>
                <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
                    <div>
                        <img
                            src={`img/hero/${heroContent.heroMobileImage}`}
                            className="img-fluid main-img-mobile d-sm-block d-lg-none"
                            alt="Gonzalo Chacaltana"
                        />
                        <h1 className="text-uppercase poppins-font">
                            {heroContent.heroTitleName}.
                            <span>{heroContent.heroDesignation}</span>
                        </h1>
                        <p className="open-sans-font">{heroContent.heroDescriptions}</p>
                        <div className="contact">
                            <Social />
                        </div>

                    </div>
                </div>
            </div>
            {/* End home-details-container */}
        </>
    );
};

export default Hero;
